@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  scroll-behavior: smooth;
}

.h1 {
  color: white;
}

.tooltip-custom {
    @apply z-50 max-w-xs bg-gray-900 text-white text-sm px-3 py-2 rounded-lg shadow-lg border border-white/10;
}

/* Tooltip styling */
.react-tooltip {
    background-color: rgba(17, 17, 17, 0.95) !important;
    border: 1px solid rgba(255, 255, 255, 0.1) !important;
    backdrop-filter: blur(8px) !important;
    padding: 8px 12px !important;
    border-radius: 8px !important;
    font-size: 13px !important;
    z-index: 99999 !important;
    max-width: 300px !important;
}

.react-tooltip.show {
    opacity: 1 !important;
}

.react-tooltip-arrow {
    display: none !important;
}